export const ExperimentsConsts = {
  AlwaysShowComplexPhoneField: 'specs.bookings.AlwaysShowComplexPhoneField',
  Cart: 'specs.bookings.cart',
  HideCouponInFormPage: 'specs.bookings.HideCouponInFormPage',
  SendAddressToEcomCheckoutAndCart:
    'specs.bookings.sendAddressToEcomCheckoutAndCart',
  ChangePricingPlanNavigationToNewApi:
    'specs.bookings.ChangePricingPlanNavigationToNewApi',
  HidePriceWhenBuyinAPlanInDynamicPriceDropdown:
    'specs.bookings.HidePriceWhenBuyinAPlanInDynamicPriceDropdown',
  BookingsFormWidgetCssOptimization:
    'specs.bookings.BookingsFormWidgetCssOptimization',
  EditorOOIFormPage: 'specs.bookings.EditorOOIFormPage',
  DisableFormValidationWhenPurchasingAPricingPlan:
    'specs.bookings.DisableFormValidationWhenPurchasingAPricingPlan',
  DeleteFullAddressFromCollapseFormValues:
    'specs.bookings.DeleteFullAddressFromCollapseFormValues',
  MultiServiceAppointment: 'specs.bookings.multiServiceAppointment',
  FormPaymentOptionNewDropdown: 'specs.bookings.FormPaymentOptionNewDropdown',
  AddShouldCallConsistantQueryToGetScheduleAvailability:
    'specs.bookings.AddShouldCallConsistantQueryToGetScheduleAvailability',
  queryServiceOptionsAndVariants:
    'specs.bookings.queryServiceOptionsAndVariants',
  SplitJapaneseNamesOnForm: 'specs.bookings.SplitJapaneseNamesOnForm',
  AdditionalTimeSlotsInFormPlugin:
    'specs.bookings.AdditionalTimeSlotsInFormPlugin',
  ShouldCheckConflictsAtFormLoad:
    'specs.bookings.ShouldCheckConflictsAtFormLoad',
  SendFirstNameAsEmailWhenFIrstNameIsEmpty:
    'specs.bookings.SendFirstNameAsEmailWhenFIrstNameIsEmpty',
};
